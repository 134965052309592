<template>
    <div id="app">
        <div class="text-center mt-5 position-absolute f-logo">
            <img src="./assets/logo-dc.svg" width="100%" />
        </div>
        <Counter msg="Welcome to Your Vue.js App" />
    </div>
</template>

<script>
import Counter from "./components/Counter.vue";

export default {
    name: "App",
    components: {
        Counter,
    },
};
</script>
<style lang="scss">
body,
html {
    height: 100%;
}

.f-logo {
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 250px;
}

#app {
    height: 100%;
}
</style>
