<template>
    <div class="container h-100">
        <countdown v-if="time" :time="time">
            <template slot-scope="props">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="text-center col-4 col-sm-4">
                        <div class="rounded p-3 mb-2 shadow">
                            <h1>{{ props.hours }}</h1>
                        </div>
                        H
                    </div>
                    <div class="text-center col-4 col-sm-4">
                        <div class="rounded p-3 mb-2 shadow">
                            <h1>{{ props.minutes }}</h1>
                        </div>
                        M
                    </div>
                    <div class="text-center col-4 col-sm-4">
                        <div class="rounded shadow p-3 mb-2">
                            <h1>{{ props.seconds }}</h1>
                        </div>
                        S
                    </div>
                </div>
                <div class="position-absolute div-config">
                    <button class="btn btn-dark" @click="config()">
                        <img src="../assets/cog-solid.svg" width="20" alt="Config" />
                    </button>
                </div>
            </template>
        </countdown>
        <template v-else>
            <div class="row h-100 justify-content-center align-items-center">
                <div class="text-center col">
                    <input type="text" class="text-center form-control form-control-lg" v-model="h" />
                    <br />H
                </div>
                <div class="text-center col">
                    <input type="text" class="text-center form-control form-control-lg" v-model="m" />
                    <br />
                    M
                </div>
                <div class="text-center col">
                    <input type="text" class="text-center form-control form-control-lg" v-model="s" />
                    <br />
                    S
                </div>
                <div class="text-center col">
                    <button class="btn btn-lg btn-block btn-dark" @click="setTime()">
                        <img src="../assets/chevron-right-solid.svg" height="35" />
                    </button>
                    <br /><span class="text-light">&nbsp;_</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            h: 0,
            m: 15,
            s: 0,
            time: null,
        };
    },
    methods: {
        setTime() {
            this.time = (this.h * 3600 + this.m * 60 + this.s) * 1000;
        },
        config() {
            this.time = null;
        },
    },
};
</script>
<style scoped>
h1 {
    margin: 0;
    padding: 0;
    font-size: 60px;
}

.div-config {
    bottom: 10px;
    right: 10px;
}
</style>
